export default {
  type: "categories",
  name: null,
  color: "#2dce89",
  icon: null,
  excerpt: "",
  relationshipNames: ["organization", "parent"],
  organization: {
    type: "organizations",
    id: null,
  },
  parent: {
    type: "categories",
    id: null,
  },
};
