<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <!-- Organization -->
    <base-input
      :label="`${$t('COMMON.ORGANIZATION')} (*)`"
      :placeholder="$t('COMMON.ORGANIZATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <organization-selector
        :organization="category.organization.id"
        :filterable="true"
        :showAll="false"
        @organizationChanged="
          (organizationId, organization) => {
            category.organization.id = organizationId;
            onFormChanged();
          }
        "
        :disabled="!!category.id"
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <base-input
      :label="`${$t('COMMON.NAME')} (*)`"
      :placeholder="$t('COMMON.NAME')"
      v-model="category.name"
      @change="onFormChanged"
    />
    <validation-error :errors="apiValidationErrors.name" />

    <!-- Excerpt -->
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('CATEGORIES.PARENT_CATEGORY')}`"
          :placeholder="$t('CATEGORIES.PARENT_CATEGORY')"
        >
          <category-selector
            :allowNone="true"
            :showAll="false"
            :multiple="false"
            :filterOrganization="category.organization.id"
            :filterIdsNotIn="idsNotIn"
            :category="category.parent ? category.parent.id : null"
            @categoryChanged="
              (categoryId) => {
                category.parent.id = categoryId;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.parent" />
      </div>
    </div>

    <base-input
      type="color"
      :label="`${$t('CATEGORIES.COLOR')}`"
      :placeholder="$t('CATEGORIES.COLOR')"
      v-model="category.color"
      @change="onFormChanged"
    />
    <validation-error :errors="apiValidationErrors.color" />

    <image-selector
      :defaultImage="category.icon"
      ressource_name="categories"
      :ressource_id="category.id ? category.id : 0"
      field="profile_image"
      @imageChanged="
        (file_url) => {
          category.icon = file_url;
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.icon" />

    <!-- Excerpt -->
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.EXCERPT')}`"
          :placeholder="$t('COMMON.EXCERPT')"
        >
          <html-editor v-model="category.excerpt" @change="onFormChanged()">
          </html-editor>
        </base-input>
        <validation-error :errors="apiValidationErrors.excerpt" />
      </div>
    </div>

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          category.id
            ? $t("CATEGORIES.EDIT_CATEGORY")
            : $t("CATEGORIES.ADD_CATEGORY")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Option, Select } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ImageSelector from "@/components/ImageSelector.vue";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import CategorySelector from "@/components/CategorySelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    HtmlEditor,
    ImageSelector,
    CategorySelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["categoryData", "formErrors", "loading"],

  data() {
    let categoryData = { ...this.categoryData };
    categoryData = this.$fillUserOrganizationData(categoryData);
    return {
      category: categoryData,
      categorySelected: null,
      idsNotIn: categoryData.id ? [categoryData.id] : [],
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let categoryData = cloneDeep(this.category);

      if (!categoryData?.parent?.id) {
        delete categoryData.parent;
      }

      categoryData = this.$fillUserOrganizationData(categoryData);
      this.$emit("categorySubmitted", categoryData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    categoryData(categoryData) {
      if (categoryData) {
        this.idsNotIn = categoryData.id ? [categoryData.id] : [];
        this.category = {
          ...this.category,
          ...cloneDeep(categoryData),
          parent: categoryData?.parent ?? {
            id: null,
            type: "categories",
          },
        };
      }
    },
  },
};
</script>
