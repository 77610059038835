var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.category.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.category.id},on:{"organizationChanged":(organizationId, organization) => {
          _vm.category.organization.id = organizationId;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.NAME')} (*)`,"placeholder":_vm.$t('COMMON.NAME')},on:{"change":_vm.onFormChanged},model:{value:(_vm.category.name),callback:function ($$v) {_vm.$set(_vm.category, "name", $$v)},expression:"category.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('CATEGORIES.PARENT_CATEGORY')}`,"placeholder":_vm.$t('CATEGORIES.PARENT_CATEGORY')}},[_c('category-selector',{attrs:{"allowNone":true,"showAll":false,"multiple":false,"filterOrganization":_vm.category.organization.id,"filterIdsNotIn":_vm.idsNotIn,"category":_vm.category.parent ? _vm.category.parent.id : null},on:{"categoryChanged":(categoryId) => {
              _vm.category.parent.id = categoryId;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.parent}})],1)]),_c('base-input',{attrs:{"type":"color","label":`${_vm.$t('CATEGORIES.COLOR')}`,"placeholder":_vm.$t('CATEGORIES.COLOR')},on:{"change":_vm.onFormChanged},model:{value:(_vm.category.color),callback:function ($$v) {_vm.$set(_vm.category, "color", $$v)},expression:"category.color"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.color}}),_c('image-selector',{attrs:{"defaultImage":_vm.category.icon,"ressource_name":"categories","ressource_id":_vm.category.id ? _vm.category.id : 0,"field":"profile_image"},on:{"imageChanged":(file_url) => {
        _vm.category.icon = file_url;
        _vm.onFormChanged();
      }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.icon}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.EXCERPT')}`,"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.category.excerpt),callback:function ($$v) {_vm.$set(_vm.category, "excerpt", $$v)},expression:"category.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}})],1)]),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.category.id ? _vm.$t("CATEGORIES.EDIT_CATEGORY") : _vm.$t("CATEGORIES.ADD_CATEGORY"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }